<template>
  <div class="employee-profile p-3 pt-4 pb-5 p-md-5">
    <b-container fluid class="m-0 p-0">
      <b-row v-if="input.id">
        <b-col cols="12" md="6" class="user-details">
          <h3 class="mt-3 mb-2 mt-md-1">User details</h3>
          <p class="mt-3">{{$root.user.firstName + ' ' + $root.user.lastName}}</p>
          <br>
          <p>{{$root.user.emailAddress}}</p>
          <br>
          <b-link @click="changePassword()">{{changePasswordText()}}</b-link>
          <RouterView />
        </b-col>
      </b-row>
    </b-container>

  </div>

</template>

<script>
  import ApiProperties from '../../../src/main/resources/application'
  import FormMixin from '@/mixins/formMixin'

  export default {
    name: 'employee-profile',
    mixins: [FormMixin],
    data: function() {
      return {
        input: this.$root.user,
        cancelChangePassword: false
      }
    },
    methods: {
      updateUser: function() {
        this.submit('post', ApiProperties.api.user, {}, this.input);
      },

      changePassword: function() {
        if (this.cancelChangePassword) {
          this.cancelChangePassword = false;
          this.$router.go(-1);
        } else {
          this.cancelChangePassword = true;
          this.$router.push({name: 'changePassword'});
        }
      },

      changePasswordText: function() {
        return (this.cancelChangePassword) ? 'Cancel' : 'Change Password';
      }
    }
  }
</script>

<style lang="scss">
  .employee-profile {
    h3 {
      span {
        vertical-align:middle;
      }

      .header-with-icon {
        color: #c3c7cd;
        font-size: 2rem;
        padding-right: 7px;
      }
    }

    .user-details {
      p {
        margin: 0;
        padding: 0;
      }
    }
  }
</style>
